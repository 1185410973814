@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-Black.otf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-BlackItalic.otf');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-Bold.otf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-BoldItalic.otf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-Book.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-BookItalic.otf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-Light.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-Medium.otf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-MediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-Thin.otf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-ThinItalic.otf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-Ultra.otf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-UltraItalic.otf');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-XLight.otf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/Gotham/Gotham-XLightItalic.otf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'kablammo';
  src: url('assets/fonts/Kablammo/Kablammo-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'lora';
  src: url('assets/fonts/Lora/Lora-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'lora';
  src: url('assets/fonts/Lora/Lora-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'lora';
  src: url('assets/fonts/Lora/Lora-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'lora';
  src: url('assets/fonts/Lora/Lora-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'lora';
  src: url('assets/fonts/Lora/Lora-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'lora';
  src: url('assets/fonts/Lora/Lora-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'lora';
  src: url('assets/fonts/Lora/Lora-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'lora';
  src: url('assets/fonts/Lora/Lora-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}
